/* Some CSS properties are overridden by the CMS (per site), see /components/layout/index.js */

:root {
  /* Palette */
  --primary: #98ac85;
  --secondary: #002a32;
  --tertiary: #f6f2eb;
  --background: #f6f2eb;
  --link-normal: #756452;
  --link-hover: #390020;
  --link-visited: #7C2E77;
  --shadow-button: url(/images/content/CTA_skugga_moerkturkos_17e9f41722.png);
  --shadow-ball: url(/images/content/borrelia_boll_600px_5010b9bda2.png);
  --shadow-line: url(/images/content/borreliakollen_kant_600px_9edc6d8c0a.png);
}